import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from '@tfagency/core/data-access';

@Pipe({
  name: 'brokerFilter',
})
export class BrokerFilterPipe implements PipeTransform {
  transform(brokers: UserModel[], brokerUid: string): UserModel[] {
    if (!brokers || !brokerUid) {
      return brokers;
    }
    return brokers.filter((item) => item.uid !== brokerUid);
  }
}
