import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: string) {
    return url
      ? this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + url)
      : 'assets/default-estate-image.png';
  }
}
