
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/stb-portal/src/environments/environment';
import { Injectable } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { Message } from '@tfagency/core/data-access';
import {
  ConnectionStateEnum,
  EventsHub,
  IEventsHubCallbacks,
  UserJoinedGroupEventModel,
  UserConnectionStatusChangedEvent,
  MessageEventModel,
} from '@tfagency/shared/util';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiEventsService implements IEventsHubCallbacks {
  private coreEventsHub!: EventsHub;
  private coreHubConnection!: HubConnection;
  connectionStatusChanged = new Subject<ConnectionStateEnum>();
  connectionState = ConnectionStateEnum.Disconnected;
  private isInitialized = false;
  private messageReceived = new BehaviorSubject<Message>({});
  messageReceived$ = this.messageReceived.asObservable();
  private _userJoinedGroup = new Subject<UserJoinedGroupEventModel>;
  userJoinedGroup$ = this._userJoinedGroup.asObservable();
  private _userConnectionStatusChanged = new Subject<UserConnectionStatusChangedEvent>;
  userConnectionStatusChanged$ =  this._userConnectionStatusChanged.asObservable();
  private _messageDeleted = new Subject<MessageEventModel>();
  messageDeleted$ = this._messageDeleted.asObservable();

  constructor(private oauthService: OAuthService) {}

   valuationCreated() {
    console.log('Valuation created');
    return;
  }

  estateAssigned() {
    console.log('Estate assigned');
    return;
  }

  messageCreated(message: Message) {
    this.messageReceived.next(message);
  }

  messageDeleted(model: MessageEventModel) {
    this._messageDeleted.next(model);
  }

  userJoinedGroup(model: UserJoinedGroupEventModel) {
    this._userJoinedGroup.next(model)
  }

  userConnectionStatusChanged(model: UserConnectionStatusChangedEvent) {
    this._userConnectionStatusChanged.next(model)
  }

  private initialize() {
    this.coreHubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl(environment.coreApiUrl + '/events', {
        accessTokenFactory: () => this.oauthService.getAccessToken(),
      })
      .withAutomaticReconnect()
      .build();
    this.coreEventsHub = new EventsHub(this.coreHubConnection);
    this.coreEventsHub.registerCallbacks(this);
    this.coreHubConnection.onclose((error) => {
      console.warn('SignalR connection is closed: ' + error?.message);
      this.setConnectionStatus(ConnectionStateEnum.Disconnected);
    });
    this.coreHubConnection.onreconnecting((error) => {
      console.warn('Signal is connection is reconnecting: ' + error?.message);
    });
  }

  setConnectionStatus(state: ConnectionStateEnum) {
    this.connectionState = state;
    this.connectionStatusChanged.next(state);
  }

  async stop() {
      await this.coreHubConnection?.stop();
  }

  async start() {
    try {
      if (!this.isInitialized) {
        this.initialize();
      }
      await this.coreHubConnection.start();
      console.log('Core hub connection started!');
      this.setConnectionStatus(ConnectionStateEnum.Connected);
    } catch (error) {
      this.setConnectionStatus(ConnectionStateEnum.Disconnected);
      setTimeout(async () => {
        await this.start();
      }, 2000);
    }
  }
}
