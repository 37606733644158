import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading.asObservable();
  private isWaiting = new BehaviorSubject<boolean>(false);
  isWaiting$ = this.isWaiting.asObservable();

  setLoading(isLoading: boolean): void {
    this.isLoading.next(isLoading);
  }

  setWaiting(isWaiting: boolean): void {
    this.isWaiting.next(isWaiting);
  }
}
