import { Pipe, PipeTransform } from '@angular/core';
import { SwedishDatePipe } from './swedish-date.pipe';

@Pipe({
  name: 'transformArrivedMessageDate',
  pure: false,
})
export class TransformArrivedMessageDatePipe implements PipeTransform {
  transform(date: Date): string {
    let dateString: string;
    const thenDate = new Date(date);
    const nowDate = new Date();
    const msBetweenDates = Math.abs(thenDate.getTime() - nowDate.getTime());
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    if (hoursBetweenDates < 24) {
      dateString = new SwedishDatePipe().transform(date, 'hours') as string;
      // console.log('date is within 24 hours');
    } else if (hoursBetweenDates > 24 && hoursBetweenDates <= 168) {
      dateString = new SwedishDatePipe().transform(date, 'weekday') as string;
      // console.log('date is within 1 week old');
    } else {
      dateString = new SwedishDatePipe().transform(date) as string;
      // console.log('date is older that 7 days');
    }
    return dateString;
  }
}
