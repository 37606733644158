/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type RoleType = 'Unknown' | 'Admin' | 'BrokerAgent' | 'Client' | 'Assistant' | 'Advisor' | 'System';

export const RoleType = {
    Unknown: 'Unknown' as RoleType,
    Admin: 'Admin' as RoleType,
    BrokerAgent: 'BrokerAgent' as RoleType,
    Client: 'Client' as RoleType,
    Assistant: 'Assistant' as RoleType,
    Advisor: 'Advisor' as RoleType,
    System: 'System' as RoleType
};