import { NgModule } from '@angular/core';
import { UniqueSellingPointsComponent } from './components/unique-selling-points/unique-selling-points.component';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import {
  BrokerFilterPipe,
  EnumFormmattingPipe,
  EnumTranslatingPipe,
  MyCurrencyPipe,
  SharedUtilsModule,
} from '@tfagency/shared/util';
import { LoadingComponent } from './loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ChatBubbleComponent } from './components/chat-bubble/chat-bubble.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChatButtonScrollComponent } from './components/chat-button-scroll/chat-button-scroll.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { WithLoadingPipe } from './loading.pipe';
import { UniqueSellingPointsEditComponent } from './components/unique-selling-points-edit/unique-selling-points-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AddSellersComponent } from './components/add-sellers/add-sellers.component';
import { AdditionalInfoComponent } from './components/additional-info/additional-info.component';
import { AssignBrokersComponent } from './components/assign-brokers/assign-brokers.component';
import { AssignPropertySuccessDialogComponent } from './components/assign-property-success-dialog/assign-property-success-dialog.component';

@NgModule({
  declarations: [
    UniqueSellingPointsComponent,
    ChatBubbleComponent,
    MyCurrencyPipe,
    EnumFormmattingPipe,
    EnumTranslatingPipe,
    LoadingComponent,
    BrokerFilterPipe,
    ChatButtonScrollComponent,
    WithLoadingPipe,
    UniqueSellingPointsEditComponent,
    AdditionalInfoComponent,
    AddSellersComponent,
    AssignBrokersComponent,
    AssignPropertySuccessDialogComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    MatDividerModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatCardModule,
    SharedUtilsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
  ],
  exports: [
    UniqueSellingPointsComponent,
    ChatBubbleComponent,
    MyCurrencyPipe,
    EnumFormmattingPipe,
    TranslateModule,
    EnumTranslatingPipe,
    LoadingComponent,
    BrokerFilterPipe,
    ChatButtonScrollComponent,
    WithLoadingPipe,
    SharedUtilsModule,
    UniqueSellingPointsEditComponent,
    AdditionalInfoComponent,
    AddSellersComponent,
    AssignBrokersComponent,
    AssignPropertySuccessDialogComponent,
  ],
})
export class SharedModule {}
