import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellComponent } from './shell/shell/shell.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { RouteConstants } from './shared/constants/route.constants';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: RouteConstants.GROUP_CHAT_ROUTE,
    loadChildren: () =>
      import('./group-chat/group-chat.module').then((m) => m.GroupChatModule),
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: RouteConstants.DASHBOARD_ROUTE,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: RouteConstants.PROPERTIES_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./stb-properties/stb-properties.module').then(
                (m) => m.StbPropertiesModule
              ),
          },
          {
            path: RouteConstants.ADD_ROUTE,
            loadChildren: () =>
              import('./create-property/create-property.module').then(
                (m) => m.CreatePropertyModule
              ),
          },
          {
            path: RouteConstants.DUPLICATE_ROUTE,
            loadChildren: () =>
              import('./duplicate-property/duplicate-property.module').then(
                (m) => m.DuplicatePropertyModule
              ),
          },
          {
            path: ':uid',
            loadChildren: () =>
              import('./single-property/single-property.module').then(
                (m) => m.SinglePropertyModule
              ),
          },
        ],
      },

      {
        path: RouteConstants.MARKET_ANALYSIS_ROUTE,
        loadChildren: () =>
          import('./market-analysis/market-analysis.module').then(
            (m) => m.MarketAnalysisModule
          ),
      },
      {
        path: RouteConstants.ASSIGN_PROPERTIES_ROUTE,
        loadChildren: () =>
          import('./assign-properties/assign-properties.module').then(
            (m) => m.AssignPropertiesModule
          ),
      },
      {
        path: '**',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
      //https://medium.com/@TheAkshayKolte/angular-routing-events-e914478c9c
      //{ enableTracing: true } //TODO: disable for PROD
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
