<mat-toolbar color="primary" class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center">
  <img src="assets/logo.svg" width="250" />
  <div fxLayout="row" fxLayoutGap="32px">
    <button mat-icon-button class="button-size" (click)="openGroupChat()">
      <mat-icon color="accent">chat</mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="menu" class="button-size">
      <mat-icon color="accent">account_circle</mat-icon>
    </button>
  </div>

  <mat-menu #menu="matMenu">
    <h2>{{ fullName }}</h2>
    <button mat-menu-item [matMenuTriggerFor]="language">
      {{ 'Language' | translate }}
    </button>
    <button mat-menu-item (click)="logoff()">
      <mat-icon>logout</mat-icon>
      <span>{{ 'Log Out' | translate }}</span>
    </button>
  </mat-menu>
  <mat-menu #language="matMenu">
    <a *ngFor="let locale of localesList" mat-menu-item (click)="selectLaguage(locale.code)">
      {{ locale.label }}
    </a>
  </mat-menu>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav color="accent" class="side_panel" opened mode="side" [disableClose]="true">
    <mat-nav-list>
      <a mat-list-item (click)="navigateToPage(mainRoutes.dashboardRoute)">
        {{ 'Dashboard' | translate }}
      </a>
      <a mat-list-item (click)="navigateToPage(mainRoutes.propertiesRoute)">
        {{ 'STB Properties' | translate }}
      </a>
      <a mat-list-item (click)="navigateToPage(mainRoutes.marketAnalysisRoute)">
        {{ 'Market Analysis' | translate }}
      </a>
      <a mat-list-item *ngIf="role === roleTypes.Admin || role === roleTypes.Assistant"
        (click)="navigateToPage(mainRoutes.assignPropertiesRoute)">
        {{ 'Assign Properties' | translate }}
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="loading-bar-container">
      <mat-progress-bar mode="indeterminate" *ngIf="loadingService.isLoading$ | async"></mat-progress-bar>
    </div>
    <router-outlet *ngIf="!errorMessage; else error"></router-outlet>
    <ng-template #error>
      <tfagency-error-message [errorMessage]="errorMessage" (retry)="reload()">
      </tfagency-error-message>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>