import {
  ErrorService,
  LoadingInterceptor,
  locales,
  ServerHttpInterceptor,
  SharedUtilsModule,
  StorageKeyConstants,
} from '@tfagency/shared/util';
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import * as Sentry from "@sentry/angular-ivy";
import {
  ApiModule as AuthorityApiModule,
  Configuration as AuthorityConfiguration,
} from '@tfagency/authority/data-access';
import { ApiModule as CoreApiModule } from '@tfagency/core/data-access';
import { Configuration as CoreConfiguration } from '@tfagency/core/data-access';
import { environment } from '../environments/environment';
import { ShellModule } from './shell/shell.module';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { HomeComponent } from './home/home.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeSwedish from '@angular/common/locales/sv';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './shared/services/custom-mat-paginator-intl.service';
registerLocaleData(localeSwedish);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    MatDialogModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ShellModule,
    SharedUtilsModule,
    AuthorityApiModule.forRoot(
      () =>
        new AuthorityConfiguration({
          basePath: environment.authorityApiUrl,
          withCredentials: true,
        })
    ),
    CoreApiModule.forRoot(
      () =>
        new CoreConfiguration({
          basePath: environment.coreApiUrl,
          withCredentials: false,
        })
    ),
    OAuthModule.forRoot({
      // https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/working-with-httpinterceptors.html
      resourceServer: {
        allowedUrls: [environment.coreApiUrl],
        sendAccessToken: true, //TODO: implement OAuthResourceServerErrorHandler as per above article
      },
    }),
    TranslateModule.forRoot({
      defaultLanguage:
        (localStorage.getItem(StorageKeyConstants.LANGUAGE_CODE) as string) !=
        null
          ? (localStorage.getItem(StorageKeyConstants.LANGUAGE_CODE) as string)
          : locales.defaultLanguge.code,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerHttpInterceptor,
      multi: true,
      deps: [ErrorService, OAuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
