import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tfagency-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
  @Input() errorMessage = '';
  @Output() retry = new EventEmitter();

  reload() {
    this.retry.emit();
  }
}
