export const environment = {
  production: true,
  authorityApiUrl: 'https://authority.tfagency.se/api',
  coreApiUrl: 'https://portal.tfagency.se/api',
  sentryDSN: 'https://786c61516c57474283bec74bd552896e@o4505385050898432.ingest.us.sentry.io/4505385077440512',
  sentryEnvironment: 'production',
  tfAgencyPropertyUrl: 'https://tfagency.se/bostader/',
  vitecPropertyUrl: 'https://express.maklare.vitec.net/objekt/',
  vitecUserUrl: 'https://express.maklare.vitec.net/kontakt/',
};
