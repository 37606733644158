export class RouteConstants {
  static readonly GROUP_CHAT_ROUTE = 'group-chat';
  static readonly DASHBOARD_ROUTE = 'dashboard';
  static readonly PROPERTIES_ROUTE = 'properties';
  static readonly MARKET_ANALYSIS_ROUTE = 'market-analysis';
  static readonly ASSIGN_PROPERTIES_ROUTE = 'assign-properties';
  static readonly SINGLE_PROPERTY_ROUTE = 'property';
  static readonly ADD_ROUTE = 'add';
  static readonly DUPLICATE_ROUTE = 'duplicate';
}
