/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AssignCommand } from '../model/assignCommand';
import { CreateEstateByBrokerCommand } from '../model/createEstateByBrokerCommand';
import { CreateEstateByBrokerCommandResult } from '../model/createEstateByBrokerCommandResult';
import { CreateEstateCommand } from '../model/createEstateCommand';
import { CreateEstateCommandResult } from '../model/createEstateCommandResult';
import { CreateNoteToBrokerCommand } from '../model/createNoteToBrokerCommand';
import { CreateNoteToBrokerCommandResult } from '../model/createNoteToBrokerCommandResult';
import { CreateSellerCommand } from '../model/createSellerCommand';
import { CreateSellerCommandResult } from '../model/createSellerCommandResult';
import { DeleteEstateCommand } from '../model/deleteEstateCommand';
import { DuplicateEstateCommand } from '../model/duplicateEstateCommand';
import { DuplicateEstateCommandResult } from '../model/duplicateEstateCommandResult';
import { GetAutoValuationQueryResult } from '../model/getAutoValuationQueryResult';
import { GetCSVFileQuery } from '../model/getCSVFileQuery';
import { GetEstateQueryResult } from '../model/getEstateQueryResult';
import { GetEstatesQueryResult } from '../model/getEstatesQueryResult';
import { GetSoldEstatesQueryResult } from '../model/getSoldEstatesQueryResult';
import { GetStatisticsQueryResult } from '../model/getStatisticsQueryResult';
import { InlineResponse201 } from '../model/inlineResponse201';
import { InlineResponse404 } from '../model/inlineResponse404';
import { MarkEstateAsNotNewCommand } from '../model/markEstateAsNotNewCommand';
import { UpdateEstateCommand } from '../model/updateEstateCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EstatesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesCountGet(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public estatesCountGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public estatesCountGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public estatesCountGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('get',`${this.basePath}/Estates/Count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesCreateEstateByBrokerPost(body?: CreateEstateByBrokerCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateEstateByBrokerCommandResult>;
    public estatesCreateEstateByBrokerPost(body?: CreateEstateByBrokerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateEstateByBrokerCommandResult>>;
    public estatesCreateEstateByBrokerPost(body?: CreateEstateByBrokerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateEstateByBrokerCommandResult>>;
    public estatesCreateEstateByBrokerPost(body?: CreateEstateByBrokerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateEstateByBrokerCommandResult>('post',`${this.basePath}/Estates/CreateEstateByBroker`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesCreateNoteToBrokerPost(body?: CreateNoteToBrokerCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateNoteToBrokerCommandResult>;
    public estatesCreateNoteToBrokerPost(body?: CreateNoteToBrokerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateNoteToBrokerCommandResult>>;
    public estatesCreateNoteToBrokerPost(body?: CreateNoteToBrokerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateNoteToBrokerCommandResult>>;
    public estatesCreateNoteToBrokerPost(body?: CreateNoteToBrokerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateNoteToBrokerCommandResult>('post',`${this.basePath}/Estates/CreateNoteToBroker`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesDuplicateEstatePost(body?: DuplicateEstateCommand, observe?: 'body', reportProgress?: boolean): Observable<DuplicateEstateCommandResult>;
    public estatesDuplicateEstatePost(body?: DuplicateEstateCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DuplicateEstateCommandResult>>;
    public estatesDuplicateEstatePost(body?: DuplicateEstateCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DuplicateEstateCommandResult>>;
    public estatesDuplicateEstatePost(body?: DuplicateEstateCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DuplicateEstateCommandResult>('post',`${this.basePath}/Estates/DuplicateEstate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param estateUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesEstateUidAutovaluationGet(estateUid: string, observe?: 'body', reportProgress?: boolean): Observable<GetAutoValuationQueryResult>;
    public estatesEstateUidAutovaluationGet(estateUid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetAutoValuationQueryResult>>;
    public estatesEstateUidAutovaluationGet(estateUid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetAutoValuationQueryResult>>;
    public estatesEstateUidAutovaluationGet(estateUid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (estateUid === null || estateUid === undefined) {
            throw new Error('Required parameter estateUid was null or undefined when calling estatesEstateUidAutovaluationGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetAutoValuationQueryResult>('get',`${this.basePath}/Estates/${encodeURIComponent(String(estateUid))}/Autovaluation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sellerFilterName 
     * @param sellerFilterEmailAddress 
     * @param sellerFilterPhoneNumber 
     * @param sellerFilterSSN 
     * @param estateFilterAddress 
     * @param estateFilterArea 
     * @param estateFilterLivingAreaRangeFrom 
     * @param estateFilterLivingAreaRangeTo 
     * @param estateFilterAdditionalAreaRangeFrom 
     * @param estateFilterAdditionalAreaRangeTo 
     * @param estateFilterPlotAreaRangeFrom 
     * @param estateFilterPlotAreaRangeTo 
     * @param estateFilterRoomCountRangeFrom 
     * @param estateFilterRoomCountRangeTo 
     * @param estateFilterFloorRangeFrom 
     * @param estateFilterFloorRangeTo 
     * @param estateFilterHasElevator 
     * @param estateFilterHasBalcony 
     * @param estateFilterHasFireplace 
     * @param estateFilterHasPendingMessages 
     * @param estateFilterIsNewEstate 
     * @param estateFilterIsPublishedOnline 
     * @param estateFilterIsAssigned 
     * @param estateFilterHasApplication 
     * @param valuationFilterIsValuated 
     * @param valuationFilterRangeFrom 
     * @param valuationFilterRangeTo 
     * @param valuationFilterIsDraftCurrentMonth 
     * @param valuationFilterIsSkippedCurrentMonth 
     * @param valuationFilterDraftHasNoCommentCurrentMonth 
     * @param valuationFilterIsDraftMultivaluationCurrentMonth 
     * @param brokerOnlyPrimary 
     * @param pageNumber 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesGet(sellerFilterName?: string, sellerFilterEmailAddress?: string, sellerFilterPhoneNumber?: string, sellerFilterSSN?: string, estateFilterAddress?: string, estateFilterArea?: string, estateFilterLivingAreaRangeFrom?: number, estateFilterLivingAreaRangeTo?: number, estateFilterAdditionalAreaRangeFrom?: number, estateFilterAdditionalAreaRangeTo?: number, estateFilterPlotAreaRangeFrom?: number, estateFilterPlotAreaRangeTo?: number, estateFilterRoomCountRangeFrom?: number, estateFilterRoomCountRangeTo?: number, estateFilterFloorRangeFrom?: number, estateFilterFloorRangeTo?: number, estateFilterHasElevator?: boolean, estateFilterHasBalcony?: boolean, estateFilterHasFireplace?: boolean, estateFilterHasPendingMessages?: boolean, estateFilterIsNewEstate?: boolean, estateFilterIsPublishedOnline?: boolean, estateFilterIsAssigned?: boolean, estateFilterHasApplication?: boolean, valuationFilterIsValuated?: boolean, valuationFilterRangeFrom?: number, valuationFilterRangeTo?: number, valuationFilterIsDraftCurrentMonth?: boolean, valuationFilterIsSkippedCurrentMonth?: boolean, valuationFilterDraftHasNoCommentCurrentMonth?: boolean, valuationFilterIsDraftMultivaluationCurrentMonth?: boolean, brokerOnlyPrimary?: boolean, pageNumber?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<GetEstatesQueryResult>;
    public estatesGet(sellerFilterName?: string, sellerFilterEmailAddress?: string, sellerFilterPhoneNumber?: string, sellerFilterSSN?: string, estateFilterAddress?: string, estateFilterArea?: string, estateFilterLivingAreaRangeFrom?: number, estateFilterLivingAreaRangeTo?: number, estateFilterAdditionalAreaRangeFrom?: number, estateFilterAdditionalAreaRangeTo?: number, estateFilterPlotAreaRangeFrom?: number, estateFilterPlotAreaRangeTo?: number, estateFilterRoomCountRangeFrom?: number, estateFilterRoomCountRangeTo?: number, estateFilterFloorRangeFrom?: number, estateFilterFloorRangeTo?: number, estateFilterHasElevator?: boolean, estateFilterHasBalcony?: boolean, estateFilterHasFireplace?: boolean, estateFilterHasPendingMessages?: boolean, estateFilterIsNewEstate?: boolean, estateFilterIsPublishedOnline?: boolean, estateFilterIsAssigned?: boolean, estateFilterHasApplication?: boolean, valuationFilterIsValuated?: boolean, valuationFilterRangeFrom?: number, valuationFilterRangeTo?: number, valuationFilterIsDraftCurrentMonth?: boolean, valuationFilterIsSkippedCurrentMonth?: boolean, valuationFilterDraftHasNoCommentCurrentMonth?: boolean, valuationFilterIsDraftMultivaluationCurrentMonth?: boolean, brokerOnlyPrimary?: boolean, pageNumber?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEstatesQueryResult>>;
    public estatesGet(sellerFilterName?: string, sellerFilterEmailAddress?: string, sellerFilterPhoneNumber?: string, sellerFilterSSN?: string, estateFilterAddress?: string, estateFilterArea?: string, estateFilterLivingAreaRangeFrom?: number, estateFilterLivingAreaRangeTo?: number, estateFilterAdditionalAreaRangeFrom?: number, estateFilterAdditionalAreaRangeTo?: number, estateFilterPlotAreaRangeFrom?: number, estateFilterPlotAreaRangeTo?: number, estateFilterRoomCountRangeFrom?: number, estateFilterRoomCountRangeTo?: number, estateFilterFloorRangeFrom?: number, estateFilterFloorRangeTo?: number, estateFilterHasElevator?: boolean, estateFilterHasBalcony?: boolean, estateFilterHasFireplace?: boolean, estateFilterHasPendingMessages?: boolean, estateFilterIsNewEstate?: boolean, estateFilterIsPublishedOnline?: boolean, estateFilterIsAssigned?: boolean, estateFilterHasApplication?: boolean, valuationFilterIsValuated?: boolean, valuationFilterRangeFrom?: number, valuationFilterRangeTo?: number, valuationFilterIsDraftCurrentMonth?: boolean, valuationFilterIsSkippedCurrentMonth?: boolean, valuationFilterDraftHasNoCommentCurrentMonth?: boolean, valuationFilterIsDraftMultivaluationCurrentMonth?: boolean, brokerOnlyPrimary?: boolean, pageNumber?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEstatesQueryResult>>;
    public estatesGet(sellerFilterName?: string, sellerFilterEmailAddress?: string, sellerFilterPhoneNumber?: string, sellerFilterSSN?: string, estateFilterAddress?: string, estateFilterArea?: string, estateFilterLivingAreaRangeFrom?: number, estateFilterLivingAreaRangeTo?: number, estateFilterAdditionalAreaRangeFrom?: number, estateFilterAdditionalAreaRangeTo?: number, estateFilterPlotAreaRangeFrom?: number, estateFilterPlotAreaRangeTo?: number, estateFilterRoomCountRangeFrom?: number, estateFilterRoomCountRangeTo?: number, estateFilterFloorRangeFrom?: number, estateFilterFloorRangeTo?: number, estateFilterHasElevator?: boolean, estateFilterHasBalcony?: boolean, estateFilterHasFireplace?: boolean, estateFilterHasPendingMessages?: boolean, estateFilterIsNewEstate?: boolean, estateFilterIsPublishedOnline?: boolean, estateFilterIsAssigned?: boolean, estateFilterHasApplication?: boolean, valuationFilterIsValuated?: boolean, valuationFilterRangeFrom?: number, valuationFilterRangeTo?: number, valuationFilterIsDraftCurrentMonth?: boolean, valuationFilterIsSkippedCurrentMonth?: boolean, valuationFilterDraftHasNoCommentCurrentMonth?: boolean, valuationFilterIsDraftMultivaluationCurrentMonth?: boolean, brokerOnlyPrimary?: boolean, pageNumber?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



































        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sellerFilterName !== undefined && sellerFilterName !== null) {
            queryParameters = queryParameters.set('SellerFilter.Name', <any>sellerFilterName);
        }
        if (sellerFilterEmailAddress !== undefined && sellerFilterEmailAddress !== null) {
            queryParameters = queryParameters.set('SellerFilter.EmailAddress', <any>sellerFilterEmailAddress);
        }
        if (sellerFilterPhoneNumber !== undefined && sellerFilterPhoneNumber !== null) {
            queryParameters = queryParameters.set('SellerFilter.PhoneNumber', <any>sellerFilterPhoneNumber);
        }
        if (sellerFilterSSN !== undefined && sellerFilterSSN !== null) {
            queryParameters = queryParameters.set('SellerFilter.SSN', <any>sellerFilterSSN);
        }
        if (estateFilterAddress !== undefined && estateFilterAddress !== null) {
            queryParameters = queryParameters.set('EstateFilter.Address', <any>estateFilterAddress);
        }
        if (estateFilterArea !== undefined && estateFilterArea !== null) {
            queryParameters = queryParameters.set('EstateFilter.Area', <any>estateFilterArea);
        }
        if (estateFilterLivingAreaRangeFrom !== undefined && estateFilterLivingAreaRangeFrom !== null) {
            queryParameters = queryParameters.set('EstateFilter.LivingAreaRange.From', <any>estateFilterLivingAreaRangeFrom);
        }
        if (estateFilterLivingAreaRangeTo !== undefined && estateFilterLivingAreaRangeTo !== null) {
            queryParameters = queryParameters.set('EstateFilter.LivingAreaRange.To', <any>estateFilterLivingAreaRangeTo);
        }
        if (estateFilterAdditionalAreaRangeFrom !== undefined && estateFilterAdditionalAreaRangeFrom !== null) {
            queryParameters = queryParameters.set('EstateFilter.AdditionalAreaRange.From', <any>estateFilterAdditionalAreaRangeFrom);
        }
        if (estateFilterAdditionalAreaRangeTo !== undefined && estateFilterAdditionalAreaRangeTo !== null) {
            queryParameters = queryParameters.set('EstateFilter.AdditionalAreaRange.To', <any>estateFilterAdditionalAreaRangeTo);
        }
        if (estateFilterPlotAreaRangeFrom !== undefined && estateFilterPlotAreaRangeFrom !== null) {
            queryParameters = queryParameters.set('EstateFilter.PlotAreaRange.From', <any>estateFilterPlotAreaRangeFrom);
        }
        if (estateFilterPlotAreaRangeTo !== undefined && estateFilterPlotAreaRangeTo !== null) {
            queryParameters = queryParameters.set('EstateFilter.PlotAreaRange.To', <any>estateFilterPlotAreaRangeTo);
        }
        if (estateFilterRoomCountRangeFrom !== undefined && estateFilterRoomCountRangeFrom !== null) {
            queryParameters = queryParameters.set('EstateFilter.RoomCountRange.From', <any>estateFilterRoomCountRangeFrom);
        }
        if (estateFilterRoomCountRangeTo !== undefined && estateFilterRoomCountRangeTo !== null) {
            queryParameters = queryParameters.set('EstateFilter.RoomCountRange.To', <any>estateFilterRoomCountRangeTo);
        }
        if (estateFilterFloorRangeFrom !== undefined && estateFilterFloorRangeFrom !== null) {
            queryParameters = queryParameters.set('EstateFilter.FloorRange.From', <any>estateFilterFloorRangeFrom);
        }
        if (estateFilterFloorRangeTo !== undefined && estateFilterFloorRangeTo !== null) {
            queryParameters = queryParameters.set('EstateFilter.FloorRange.To', <any>estateFilterFloorRangeTo);
        }
        if (estateFilterHasElevator !== undefined && estateFilterHasElevator !== null) {
            queryParameters = queryParameters.set('EstateFilter.HasElevator', <any>estateFilterHasElevator);
        }
        if (estateFilterHasBalcony !== undefined && estateFilterHasBalcony !== null) {
            queryParameters = queryParameters.set('EstateFilter.HasBalcony', <any>estateFilterHasBalcony);
        }
        if (estateFilterHasFireplace !== undefined && estateFilterHasFireplace !== null) {
            queryParameters = queryParameters.set('EstateFilter.HasFireplace', <any>estateFilterHasFireplace);
        }
        if (estateFilterHasPendingMessages !== undefined && estateFilterHasPendingMessages !== null) {
            queryParameters = queryParameters.set('EstateFilter.HasPendingMessages', <any>estateFilterHasPendingMessages);
        }
        if (estateFilterIsNewEstate !== undefined && estateFilterIsNewEstate !== null) {
            queryParameters = queryParameters.set('EstateFilter.IsNewEstate', <any>estateFilterIsNewEstate);
        }
        if (estateFilterIsPublishedOnline !== undefined && estateFilterIsPublishedOnline !== null) {
            queryParameters = queryParameters.set('EstateFilter.IsPublishedOnline', <any>estateFilterIsPublishedOnline);
        }
        if (estateFilterIsAssigned !== undefined && estateFilterIsAssigned !== null) {
            queryParameters = queryParameters.set('EstateFilter.IsAssigned', <any>estateFilterIsAssigned);
        }
        if (estateFilterHasApplication !== undefined && estateFilterHasApplication !== null) {
            queryParameters = queryParameters.set('EstateFilter.HasApplication', <any>estateFilterHasApplication);
        }
        if (valuationFilterIsValuated !== undefined && valuationFilterIsValuated !== null) {
            queryParameters = queryParameters.set('ValuationFilter.IsValuated', <any>valuationFilterIsValuated);
        }
        if (valuationFilterRangeFrom !== undefined && valuationFilterRangeFrom !== null) {
            queryParameters = queryParameters.set('ValuationFilter.Range.From', <any>valuationFilterRangeFrom);
        }
        if (valuationFilterRangeTo !== undefined && valuationFilterRangeTo !== null) {
            queryParameters = queryParameters.set('ValuationFilter.Range.To', <any>valuationFilterRangeTo);
        }
        if (valuationFilterIsDraftCurrentMonth !== undefined && valuationFilterIsDraftCurrentMonth !== null) {
            queryParameters = queryParameters.set('ValuationFilter.IsDraftCurrentMonth', <any>valuationFilterIsDraftCurrentMonth);
        }
        if (valuationFilterIsSkippedCurrentMonth !== undefined && valuationFilterIsSkippedCurrentMonth !== null) {
            queryParameters = queryParameters.set('ValuationFilter.IsSkippedCurrentMonth', <any>valuationFilterIsSkippedCurrentMonth);
        }
        if (valuationFilterDraftHasNoCommentCurrentMonth !== undefined && valuationFilterDraftHasNoCommentCurrentMonth !== null) {
            queryParameters = queryParameters.set('ValuationFilter.DraftHasNoCommentCurrentMonth', <any>valuationFilterDraftHasNoCommentCurrentMonth);
        }
        if (valuationFilterIsDraftMultivaluationCurrentMonth !== undefined && valuationFilterIsDraftMultivaluationCurrentMonth !== null) {
            queryParameters = queryParameters.set('ValuationFilter.IsDraftMultivaluationCurrentMonth', <any>valuationFilterIsDraftMultivaluationCurrentMonth);
        }
        if (brokerOnlyPrimary !== undefined && brokerOnlyPrimary !== null) {
            queryParameters = queryParameters.set('BrokerOnlyPrimary', <any>brokerOnlyPrimary);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEstatesQueryResult>('get',`${this.basePath}/Estates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param estateUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesGetImageGet(estateUid?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public estatesGetImageGet(estateUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public estatesGetImageGet(estateUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public estatesGetImageGet(estateUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (estateUid !== undefined && estateUid !== null) {
            queryParameters = queryParameters.set('EstateUid', <any>estateUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/Estates/GetImage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesGetStatisticsGet(observe?: 'body', reportProgress?: boolean): Observable<GetStatisticsQueryResult>;
    public estatesGetStatisticsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetStatisticsQueryResult>>;
    public estatesGetStatisticsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetStatisticsQueryResult>>;
    public estatesGetStatisticsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetStatisticsQueryResult>('get',`${this.basePath}/Estates/GetStatistics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesPost(body?: CreateEstateCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateEstateCommandResult>;
    public estatesPost(body?: CreateEstateCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateEstateCommandResult>>;
    public estatesPost(body?: CreateEstateCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateEstateCommandResult>>;
    public estatesPost(body?: CreateEstateCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateEstateCommandResult>('post',`${this.basePath}/Estates`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesSellerPost(body?: CreateSellerCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateSellerCommandResult>;
    public estatesSellerPost(body?: CreateSellerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateSellerCommandResult>>;
    public estatesSellerPost(body?: CreateSellerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateSellerCommandResult>>;
    public estatesSellerPost(body?: CreateSellerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateSellerCommandResult>('post',`${this.basePath}/Estates/Seller`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pageNumber 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesSoldGet(pageNumber?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<GetSoldEstatesQueryResult>;
    public estatesSoldGet(pageNumber?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSoldEstatesQueryResult>>;
    public estatesSoldGet(pageNumber?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSoldEstatesQueryResult>>;
    public estatesSoldGet(pageNumber?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetSoldEstatesQueryResult>('get',`${this.basePath}/Estates/Sold`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param estateUid 
     * @param firstName 
     * @param lastName 
     * @param phoneNumber 
     * @param email 
     * @param text 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesSpeculatorPost(estateUid?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, text?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public estatesSpeculatorPost(estateUid?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, text?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public estatesSpeculatorPost(estateUid?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, text?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public estatesSpeculatorPost(estateUid?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, text?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (estateUid !== undefined && estateUid !== null) {
            queryParameters = queryParameters.set('EstateUid', <any>estateUid);
        }
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('FirstName', <any>firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('LastName', <any>lastName);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('PhoneNumber', <any>phoneNumber);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('Email', <any>email);
        }
        if (text !== undefined && text !== null) {
            queryParameters = queryParameters.set('Text', <any>text);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/Estates/Speculator`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesStbCsvPost(query?: GetCSVFileQuery, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public estatesStbCsvPost(query?: GetCSVFileQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public estatesStbCsvPost(query?: GetCSVFileQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public estatesStbCsvPost(query?: GetCSVFileQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/csv'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/Estates/stb.csv`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

    /**
     * 
     * 
     * @param name 
     * @param type 
     * @param event 
     * @param customerId 
     * @param subType 
     * @param relId1 
     * @param relType1 
     * @param relId2 
     * @param relType2 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesSyncEstateContactPost(name?: string, type?: string, event?: string, customerId?: string, subType?: string, relId1?: string, relType1?: string, relId2?: string, relType2?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public estatesSyncEstateContactPost(name?: string, type?: string, event?: string, customerId?: string, subType?: string, relId1?: string, relType1?: string, relId2?: string, relType2?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public estatesSyncEstateContactPost(name?: string, type?: string, event?: string, customerId?: string, subType?: string, relId1?: string, relType1?: string, relId2?: string, relType2?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public estatesSyncEstateContactPost(name?: string, type?: string, event?: string, customerId?: string, subType?: string, relId1?: string, relType1?: string, relId2?: string, relType2?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('Name', <any>name);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('Type', <any>type);
        }
        if (event !== undefined && event !== null) {
            queryParameters = queryParameters.set('Event', <any>event);
        }
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('CustomerId', <any>customerId);
        }
        if (subType !== undefined && subType !== null) {
            queryParameters = queryParameters.set('SubType', <any>subType);
        }
        if (relId1 !== undefined && relId1 !== null) {
            queryParameters = queryParameters.set('RelId1', <any>relId1);
        }
        if (relType1 !== undefined && relType1 !== null) {
            queryParameters = queryParameters.set('RelType1', <any>relType1);
        }
        if (relId2 !== undefined && relId2 !== null) {
            queryParameters = queryParameters.set('RelId2', <any>relId2);
        }
        if (relType2 !== undefined && relType2 !== null) {
            queryParameters = queryParameters.set('RelType2', <any>relType2);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/Estates/SyncEstateContact`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param name 
     * @param type 
     * @param event 
     * @param customerId 
     * @param id 
     * @param subtype 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesSyncVitecEstateGet(name?: string, type?: string, event?: string, customerId?: string, id?: string, subtype?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public estatesSyncVitecEstateGet(name?: string, type?: string, event?: string, customerId?: string, id?: string, subtype?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public estatesSyncVitecEstateGet(name?: string, type?: string, event?: string, customerId?: string, id?: string, subtype?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public estatesSyncVitecEstateGet(name?: string, type?: string, event?: string, customerId?: string, id?: string, subtype?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('Name', <any>name);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('Type', <any>type);
        }
        if (event !== undefined && event !== null) {
            queryParameters = queryParameters.set('Event', <any>event);
        }
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('CustomerId', <any>customerId);
        }
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('Id', <any>id);
        }
        if (subtype !== undefined && subtype !== null) {
            queryParameters = queryParameters.set('Subtype', <any>subtype);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/Estates/SyncVitecEstate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesUidAssignPost(uid: string, body?: AssignCommand, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse201>;
    public estatesUidAssignPost(uid: string, body?: AssignCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse201>>;
    public estatesUidAssignPost(uid: string, body?: AssignCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse201>>;
    public estatesUidAssignPost(uid: string, body?: AssignCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling estatesUidAssignPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse201>('post',`${this.basePath}/Estates/${encodeURIComponent(String(uid))}/Assign`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesUidDelete(uid: string, body?: DeleteEstateCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public estatesUidDelete(uid: string, body?: DeleteEstateCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public estatesUidDelete(uid: string, body?: DeleteEstateCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public estatesUidDelete(uid: string, body?: DeleteEstateCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling estatesUidDelete.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/Estates/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetEstateQueryResult>;
    public estatesUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEstateQueryResult>>;
    public estatesUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEstateQueryResult>>;
    public estatesUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling estatesUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEstateQueryResult>('get',`${this.basePath}/Estates/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesUidMarkAsNotNewPost(uid: string, body?: MarkEstateAsNotNewCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public estatesUidMarkAsNotNewPost(uid: string, body?: MarkEstateAsNotNewCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public estatesUidMarkAsNotNewPost(uid: string, body?: MarkEstateAsNotNewCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public estatesUidMarkAsNotNewPost(uid: string, body?: MarkEstateAsNotNewCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling estatesUidMarkAsNotNewPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Estates/${encodeURIComponent(String(uid))}/MarkAsNotNew`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estatesUidPut(uid: string, body?: UpdateEstateCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public estatesUidPut(uid: string, body?: UpdateEstateCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public estatesUidPut(uid: string, body?: UpdateEstateCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public estatesUidPut(uid: string, body?: UpdateEstateCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling estatesUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Estates/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
