import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl
  extends MatPaginatorIntl
  implements OnDestroy
{
  unsubscribe = new Subject<void>();
  readonly ITEMS_PER_PAGE_LABEL = 'Items per page:';
  OF_LABEL = 'of';

  constructor(private translateService: TranslateService) {
    super();

    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getAndInitTranslations();
      });

    this.getAndInitTranslations();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getAndInitTranslations() {
    this.translateService
      .get([this.OF_LABEL, this.ITEMS_PER_PAGE_LABEL])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.itemsPerPageLabel = this.translateService.instant(
          this.ITEMS_PER_PAGE_LABEL
        );
        this.OF_LABEL = this.translateService.instant('of');
        this.changes.next();
      });
  }

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.OF_LABEL} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;
  };
}
