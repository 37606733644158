import { NgModule } from '@angular/core';
import { LocalDateTimePipe } from './pipes/local-date-time.pipe';
import { ShortTextPipe } from './pipes/short-text.pipe';
import { SwedishDatePipe } from './pipes/swedish-date.pipe';

@NgModule({
  declarations: [SwedishDatePipe, LocalDateTimePipe, ShortTextPipe],
  exports: [SwedishDatePipe, LocalDateTimePipe, ShortTextPipe],
})
export class SharedUtilsModule {}
