import { HubConnection } from '@microsoft/signalr';
import {
  ConnectionStatusType,
  Message,
  User,
  Valuation,
} from '@tfagency/core/data-access';

export class EventsHub {
  constructor(private connection: HubConnection) {}

  registerCallbacks(implementation: IEventsHubCallbacks) {
    this.connection.on('ValuationCreated', (model) =>
      implementation.valuationCreated(model)
    );
    this.connection.on('EstateAssigned', (model) =>
      implementation.estateAssigned(model)
    );
    this.connection.on('MessageCreated', (model) =>
      implementation.messageCreated(model)
    );
    this.connection.on('MessageDeleted', (model) =>
      implementation.messageDeleted(model)
    );
    this.connection.on('UserJoinedGroup', (model) =>
      implementation.userJoinedGroup(model)
    );
    this.connection.on('UserConnectionStatusChanged', (model) =>
      implementation.userConnectionStatusChanged(model)
    );
  }

  unregisterCallbacks(implementation: IEventsHubCallbacks) {
    this.connection.off('ValuationCreated', (model) =>
      implementation.valuationCreated(model)
    );
    this.connection.off('EstateAssigned', (model) =>
      implementation.estateAssigned(model)
    );
    this.connection.off('MessageCreated', (model) =>
      implementation.messageCreated(model)
    );
    this.connection.off('MessageDeleted', (model) =>
      implementation.messageDeleted(model)
    );
    this.connection.off('UserJoinedGroup', (model) =>
      implementation.userJoinedGroup(model)
    );
    this.connection.off('UserConnectionStatusChanged', (model) =>
      implementation.userConnectionStatusChanged(model)
    );
  }
}

export interface IEventsHubCallbacks {
  valuationCreated(model: Valuation): void;
  estateAssigned(model: EstateEventModel): void;
  messageCreated(model: Message): void;
  messageDeleted(model: MessageEventModel): void;
  userJoinedGroup(model: UserJoinedGroupEventModel): void;
  userConnectionStatusChanged(model: UserConnectionStatusChangedEvent): void;
}

export interface MessageEventModel {
  estateUid?: string | undefined;
  messageUid?: string | undefined;
}

export interface EstateEventModel {
  primaryBrokerAgentUser: User | undefined;
  userUid: string | undefined;
  estateUid: string | undefined;
}

export interface UserJoinedGroupEventModel {
  estateUid: string | undefined;
  text: string | undefined;
}

export interface UserConnectionStatusChangedEvent {
  model?: UserConnectionStatusChangedModel | undefined;
}
export interface UserConnectionStatusChangedModel {
  estateUid: string | undefined;
  userUid: string | undefined;
  status: ConnectionStatusType;
}
