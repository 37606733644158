import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { locales, StorageKeyConstants } from '@tfagency/shared/util';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { ApiEventsService } from './shared/services/api-events.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'tfagency-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'tfagency-portal';

  constructor(
    private oauthService: OAuthService,
    private translateService: TranslateService,
    private apiEventsService: ApiEventsService
  ) {
    //TODO: move to APP_INITIALIZER?
    this.oauthService.configure({
      showDebugInformation: true, //TODO: remove in PROD?

      // Url of the Identity Provider
      issuer: environment.authorityApiUrl,

      // URL of the SPA to redirect the user to after login
      redirectUri: location.origin,
      postLogoutRedirectUri: location.origin,

      // The SPA's id. The SPA is registerd with this id at the auth-server
      clientId: 'Web',
      responseType: 'code',
      useSilentRefresh: true,
      silentRefreshRedirectUri: `${location.origin}/silent-renew.html`,

      // set the scope for the permissions the client should request
      // The first three are defined by OIDC. Last is a usecase-specific one
      scope: 'openid profile offline_access api.access', //email removed

      // set to true, to receive also an id_token via OpenId Connect (OIDC) in addition to the
      // OAuth2-based access_token
      //this.oauthService.oidc = true; // ID_Token

      customQueryParams: {
        ClientTenant: 'portal',
      },

      revocationEndpoint: `${environment.authorityApiUrl}/connect/revocation`,
      logoutUrl: `${environment.authorityApiUrl}/connect/endsession`,

      silentRefreshTimeout: 60 * 1,
    });
    // Use setStorage to use sessionStorage or another implementation of the TS-type Storage
    // instead of localStorage

    //this.oauthService.setStorage(sessionStorage);
    this.oauthService.setStorage(localStorage);
  }

  async ngOnInit() {
    await lastValueFrom(
      this.translateService.use(
        (localStorage.getItem(StorageKeyConstants.LANGUAGE_CODE) as string) !=
          null
          ? (localStorage.getItem(StorageKeyConstants.LANGUAGE_CODE) as string)
          : locales.defaultLanguge.code
      )
    );
    setTimeout(async () => {
      await this.apiEventsService.stop();
      await this.apiEventsService.start();
    }, 2000);
  }
}
