import { BaseComponent, ErrorService } from '@tfagency/shared/util';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { RouteConstants } from '../shared/constants/route.constants';

@Component({
  selector: 'tfagency-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    protected override errorService: ErrorService,
    private oauthService: OAuthService,
    private router: Router
  ) {
    super(errorService);
  }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    const isAuthenticated =
      await this.oauthService.loadDiscoveryDocumentAndLogin();
    this.oauthService.setupAutomaticSilentRefresh();
    if (isAuthenticated) {
      this.router.navigate([`/${RouteConstants.DASHBOARD_ROUTE}`]);
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
