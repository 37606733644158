import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'swedishDate',
})
export class SwedishDatePipe implements PipeTransform {
  transform(date: Date | string | number | undefined, format?: string) {
    let dateFormat: string;
    switch (format) {
      case undefined:
        dateFormat = 'yyyy-MM-dd';
        break;
      case 'short-month':
        dateFormat = 'MMM';
        break;
      case 'long-month':
        dateFormat = 'MMMM';
        break;
      case 'year':
        dateFormat = 'y';
        break;
      case 'hours':
        dateFormat = 'HH:mm';
        break;
      case 'date-hours':
        dateFormat = 'yyyy-MM-dd, HH:mm';
        break;
      case 'weekday':
        dateFormat = 'EEEE';
        break;
      default:
        dateFormat = format;
        break;
    }
    return new DatePipe('en-US').transform(date, dateFormat) as string;
  }
}
