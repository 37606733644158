import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';

export class CustomValidators {
  static floorNumbers(control: AbstractControl): ValidationErrors | null {
    const formGroup = control as UntypedFormGroup;
    const livingFloor = formGroup.controls['floor'].value;
    const totalFloors = formGroup.controls['totalNumberFloors'].value;
    if (
      !livingFloor ||
      !totalFloors ||
      totalFloors > livingFloor ||
      totalFloors === livingFloor
    ) {
      return null;
    } else {
      return { floors: true };
    }
  }

  static expandedMailValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const regularExpresion =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-üåäö]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (control.value.match(regularExpresion)) {
      return null;
    } else {
      return { email: true };
    }
  }
}
