/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ValuationPeriodEnum = 'Unknown' | 'Monthly' | 'EveryTwoMonths' | 'EveryThreeMonths' | 'EveryFourMonths' | 'Never';

export const ValuationPeriodEnum = {
    Unknown: 'Unknown' as ValuationPeriodEnum,
    Monthly: 'Monthly' as ValuationPeriodEnum,
    EveryTwoMonths: 'EveryTwoMonths' as ValuationPeriodEnum,
    EveryThreeMonths: 'EveryThreeMonths' as ValuationPeriodEnum,
    EveryFourMonths: 'EveryFourMonths' as ValuationPeriodEnum,
    Never: 'Never' as ValuationPeriodEnum
};