export * from './estates.service';
import { EstatesService } from './estates.service';
export * from './marketAnalyses.service';
import { MarketAnalysesService } from './marketAnalyses.service';
export * from './messages.service';
import { MessagesService } from './messages.service';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './valuations.service';
import { ValuationsService } from './valuations.service';
export const APIS = [EstatesService, MarketAnalysesService, MessagesService, NotificationsService, UsersService, ValuationsService];
