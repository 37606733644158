import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { CurrentUserService } from '../../shared/services/current-user.service';
import {
  BaseComponent,
  ErrorService,
  LoadingService,
  locales,
  StorageKeyConstants,
} from '@tfagency/shared/util';
import { RouteConstants } from '../../shared/constants/route.constants';
import { RoleType } from '@tfagency/core/data-access';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'tfagency-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent extends BaseComponent implements OnInit, OnDestroy {
  readonly roleTypes = RoleType;
  mainRoutes = {
    dashboardRoute: `/${RouteConstants.DASHBOARD_ROUTE}`,
    propertiesRoute: `/${RouteConstants.PROPERTIES_ROUTE}`,
    marketAnalysisRoute: `/${RouteConstants.MARKET_ANALYSIS_ROUTE}`,
    assignPropertiesRoute: `/${RouteConstants.ASSIGN_PROPERTIES_ROUTE}`,
  };
  localesList = locales.localesList;

  constructor(
    protected override errorService: ErrorService,
    private router: Router,
    private oauthService: OAuthService,
    private currentUserService: CurrentUserService,
    public loadingService: LoadingService,
    private translateService: TranslateService
  ) {
    super(errorService);
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.fullName = this.currentUserService.getFullName();
    this.role = this.currentUserService.getRole();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  openGroupChat() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${RouteConstants.GROUP_CHAT_ROUTE}`])
    );
    window.open(url, '_blank');
  }

  async logoff() {
    await this.oauthService.revokeTokenAndLogout();
    localStorage.removeItem(StorageKeyConstants.FILTERS);
    localStorage.removeItem(StorageKeyConstants.PAGE_NUMBER);
    localStorage.removeItem(StorageKeyConstants.PAGE_SIZE);
    localStorage.removeItem(StorageKeyConstants.IS_NEW);
    localStorage.removeItem(StorageKeyConstants.IS_PENDING_VALUATION);
    localStorage.removeItem(StorageKeyConstants.IS_CHAT_PENDING);
  }

  reload() {
    location.reload();
  }

  navigateToPage(routePath: string) {
    this.router.navigate([`${routePath}`]);
    this.errorMessage = null;
  }

  async selectLaguage(language: string) {
    await lastValueFrom(this.translateService.use(language));
    localStorage.setItem(StorageKeyConstants.LANGUAGE_CODE, language);
  }
}
