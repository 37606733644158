import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
  name: 'mycurrency',
})
export class MyCurrencyPipe implements PipeTransform {
  transform(value?: number): string {
    const currencyCode = 'SEK';
    const digitsInfo = '1.0';
    const locale = 'en-US';
    if (!value) {
      return '/ kr';
    }
    value = value < 0 ? (value = value * -1) : value;
    return (
      formatCurrency(
        value,
        locale,
        getCurrencySymbol(currencyCode, 'wide'),
        currencyCode,
        digitsInfo
      ).substring(3) + ' kr'
    )
      .split(',')
      .join(' ');
  }
}
