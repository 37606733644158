/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type HouseCooperativeType = 'Unknown' | 'Apartment' | 'Townhouse' | 'SemiDetachedHouse' | 'ChainHouse';

export const HouseCooperativeType = {
    Unknown: 'Unknown' as HouseCooperativeType,
    Apartment: 'Apartment' as HouseCooperativeType,
    Townhouse: 'Townhouse' as HouseCooperativeType,
    SemiDetachedHouse: 'SemiDetachedHouse' as HouseCooperativeType,
    ChainHouse: 'ChainHouse' as HouseCooperativeType
};